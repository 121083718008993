@import '../../scss/mixins.scss';

.list {
    list-style: none;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    @include screenLap {
        flex-direction: column;
    }

}

.item {
    padding: 20px;
    border-bottom: 3px solid var(--second-color);
    font-size: 18px;
    line-height: 150%;
    text-align: center;
}

.title {
    margin-bottom: 20px;
}