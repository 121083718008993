@import '../../scss/mixins.scss';

.footer {
    min-height: 380px;
    background: var(--second-color);
    margin-top: 160px;
    color: var(--white-color);


}

.container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: flex-start;
    gap: 100px 30px;
    margin-top: 84px;

    @include screenMob {
        flex-direction: column-reverse;
        gap: 30px;
        margin-top: 30px;
        padding-bottom: 30px;
    }
}

.nav {
    display: grid;
    gap: 10px;

    @include screenMob {
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        width: 100%;
    }

}

.links {
    display: grid;
    gap: 10px;
}