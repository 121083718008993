@import '../../scss/mixins.scss';

.header {
    border-bottom: 1px solid rgb(0, 0, 0);
    padding: 30px 0 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include screenLap {
        border-bottom: none;
    }
}