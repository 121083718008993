.title {
    margin-bottom: 20px;
}

.imgWrapper {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    overflow-y: auto;

}