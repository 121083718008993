.modal {
    position: fixed;
    z-index: 20000;
    inset: 0;
    display: grid;
    grid-template-columns: minmax(300px, 95vw);
    grid-template-rows: minmax(300px, 90vh);
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    transition: .4s linear;

    &Open {
        visibility: visible;
        opacity: 1;
        transition: .4s linear;
    }
}

.overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    inset: 0;
}

.window {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 5px 50px rgba(255, 255, 255, 0.5);
    padding: 20px 0px;
    padding-top: 0;
    overflow: auto;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid #000;
    margin-bottom: 30px;
    font-size: 1.5em;

    &__title {
        margin-left: 20px;
    }

    &__btnClose {
        margin-right: 10px;
    }
}