.title {
    margin-bottom: 40px;
}

.text {
    margin-top: 40px;
}

.btn {
    margin-top: 60px;
}