:root {
    --main-bg-color: #f3efea;
    --white-color: #f6f6f6;
    --active-link-color: #ffcca8;
    --black-color: #323232;
    --first-color: #4c6d77;
    --second-color: #715b4b;
    --title-color: #57463a;
    --btn-color: #a1b29f;


}