.container {
    --size: 400px;
    max-width: 100%;
    width: var(--size);
    margin: 0 auto;

    @media screen and (max-width:500px) {
        padding: 0 20px;
    }
}

.slider {
    position: relative;
    overflow: hidden;
    width: 100%;
    border-radius: 20px;
    box-shadow: 5px 5px 20px rgba(0, 40, 34, 0.4);

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }
}

.before {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: 2;
    width: 50%;
    height: 100%;
}

.change {
    z-index: 10;
    position: absolute;
    left: 50%;
    top: 0;
    width: 3px;
    height: 100%;
    background-image: linear-gradient(rgb(0 43 77), #00bcd4);
    box-shadow: -3px 0px 10px rgb(0, 63, 76);
    cursor: grab;
}

.circle {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-image: linear-gradient(rgb(0 43 77), #00bcd4);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    box-shadow: 2px 2px 10px rgb(4, 88, 105);

    @media screen and (max-width:500px) {
        width: 20px;
        height: 20px;
    }
}