@import '../../../scss/mixins.scss';

.list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}


.item {
    list-style: none;
    flex: 1 0 auto;
    text-align: center;
    padding: 20px;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}


.figure {
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 20px;

    img {

        max-width: 200px;
        max-height: 200px;
    }

    figcaption {
        display: grid;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }


}

.modalContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include screenLap {
        grid-template-columns: 1fr;
        row-gap: 40px;
        justify-items: center;
    }

    .beforeAfter {
        max-height: 600px;


        @include screenDesc {
            max-height: 550px;
        }

        @include screenLapBig {
            max-height: 500px;
        }
    }
}

.col {
    display: grid;
    align-content: start;
    row-gap: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.modalTitle {
    font-size: 2.5em;
}

.modalPrice {
    font-size: 2em;
}

.modalList {
    margin-left: 30px;
}

.modalBtn {
    justify-self: start;
}