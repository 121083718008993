.ourWork {
    display: grid;
}

.list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.item {
    list-style: none;
    text-align: center;
}

.btn {
    margin-top: 50px;
    justify-self: center;
}