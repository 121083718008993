// @import '../../scss/mixins.scss';

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: var(--main-bg-color);
    color: var(--black-color);
    font-family: OpenSans;
    font-weight: 300;
}

.wrapper {
    max-width: 1440px;
    margin: 0 auto;
    overflow-x: hidden;
    background-color: var(--main-bg-color);
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;


}

.main {
    flex: 1 0 auto;
    min-height: 100vh;
}

.container {
    margin: 0 auto;
    max-width: 1160px;
    padding: 0 20px;
}

.section {
    margin-top: 160px;

    @include screenLap {
        margin-top: 90px;
    }
}

.img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.link {
    font-family: OpenSans;
    font-weight: 300;
    color: inherit;
    text-decoration: none;
    position: relative;
    text-align: center;


    &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
        width: 0;
        height: 2px;
        background: linear-gradient(90deg, transparent, var(--first-color), transparent);
        transition: .4s linear;
    }

    &:hover::after,
    &__active::after {
        width: 80%;

    }
}

.title {
    font-family: TenorSans;
    font-size: 48px;
    font-weight: 400;
    line-height: 150%;
    margin-top: 160px;
    margin-bottom: 100px;
    color: var(--title-color);


    &Hero {
        font-family: TenorSans;
        font-size: 48px;
        font-weight: 400;
        line-height: 150%;
        margin-top: 30px;
        margin-bottom: 40px;
        color: var(--title-color);

        @include screenDesc {
            font-size: 42px;
        }

        @include screenLap {
            font-size: 32px;
        }
    }

    @include screenDesc {
        font-size: 42px;
    }

    @include screenLap {
        font-size: 32px;
        text-align: center;
        margin-top: 90px;
        margin-bottom: 60px;
    }
}



.title3 {
    font-family: TenorSans;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 10px;
}

.text {
    font-size: 18px;
}

.quote {
    text-align: right;
}

//grids

.list {
    display: grid;
    grid-template-columns: repeat(3, minmax(min-content, 1fr));
    row-gap: 10px;
    column-gap: 10px;
    list-style: none;

    @include screenDesc {
        grid-template-columns: repeat(2, minmax(min-content, 1fr));
    }

    @include screenMob {
        grid-template-columns: repeat(1, minmax(min-content, 1fr));
    }
}

.priceRub::after {
    content: ' р.';
}