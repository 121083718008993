@font-face {
    font-family: OpenSans;
    src: url(./../assets/fonts/OpenSans-Bold.woff2);
    src: url(./../assets/fonts/OpenSans-Light.woff2);
    src: url(./../assets/fonts/OpenSans-Regular.woff2);

}

@font-face {
    font-family: TenorSans;
    src: url(./../assets/fonts/TenorSans-Regular.woff2);
}